import { Col } from "antd";
import GweLayout from "../../components/gweLayout/gweLayout";
import { TermsAndConditionInternal } from "./activate/activate";

export default function TermsAndConditionsPage() {
    return <GweLayout title="Polityka prywatności">
        <Col xs={22} md={20} lg={18} xxl={16}>
            <TermsAndConditionInternal />
        </Col>
    </GweLayout>
}