import { Col } from "antd";
import GweLayout from "../../components/gweLayout/gweLayout";

export default function FirstMonthTermsPage() {
    return <GweLayout title="Regulamin promocji „Pierwszy miesiąc za 1 zł”">
        <Col xs={22} md={20} lg={18} xxl={16}>
            <FirstMonthTermsInternal />
        </Col>
    </GweLayout>
}

function FirstMonthTermsInternal() {
    return <div className="polityka-container">
    <p><b><span>Regulamin promocji „Pierwszy miesiąc za 1 zł”</span></b></p>
    
    <p><b><span>Legenda:</span></b></p>
    
    <ul type="disc">
     <li><b><span>Organizator</span></b><span
        > – GOODWILL EXPERTS SPÓŁKA
         AKCYJNA, firma oferująca usługi w ramach platformy GoodNet, z siedzibą na
         ulicy Adama Branickiego 15, 02-972 Warszawa, Polska.</span></li>
     <li><b><span>Klient</span></b><span
        > – nowy użytkownik,
         przedsiębiorca lub firma, która korzysta z usług oferowanych przez
         Organizatora i spełnia warunki opisane w niniejszym Regulaminie.</span></li>
     <li><b><span>Konsultant</span></b><span
        > – przedstawiciel
         Organizatora, dedykowany opiekun klienta, który pomaga Klientowi w doborze
         odpowiedniego pakietu oraz pełni rolę doradcy na etapie korzystania z
         platformy GoodNet.</span></li>
     <li><b><span>Promocja</span></b><span
        > – oferta specjalna
         „Pierwszy miesiąc za 1 zł” obowiązująca na warunkach określonych w
         niniejszym Regulaminie.</span></li>
    </ul>

    <ol start="1" type="1">
    <li><b><span>Postanowienia ogólne</span></b></li>
    </ol>

    <p>1.1. Niniejszy regulamin (dalej: „Regulamin”) określa zasady
    promocji „Pierwszy miesiąc za 1 zł” (dalej: „Promocja”) organizowanej przez
    Organizatora – GOODWILL EXPERTS SPÓŁKA AKCYJNA.</p>

    <p>1.2. Promocja obowiązuje wyłącznie na terenie
    Rzeczypospolitej Polskiej.</p>

<ol start="2" type="1">
 <li><b><span>Warunki
     skorzystania z Promocji</span></b><span>
     </span></li>
</ol>

<p>2.1. Promocja jest skierowana wyłącznie do nowych Klientów,
którzy po raz pierwszy zakładają konto w systemie GoodNet, a także spełniają
następujące kryteria:</p>

<ol start="2" type="1">
 <ul type="circle">
  <li><span>Posiadają
      zarejestrowaną działalność gospodarczą (w formie co najmniej
      jednoosobowej działalności gospodarczej – JDG). </span></li>
  <li><span>Wypełnili
      formularz znajdujący się pod następującym linkiem: </span><span><a
      href="https://share-eu1.hsforms.com/1Gd7I_WS2TdqK4EMnJ-lsBw2e7nwj"><span
     >https://share-eu1.hsforms.com/1Gd7I_WS2TdqK4EMnJ-lsBw2e7nwj</span></a></span><span
     >.</span></li>
 </ul>
</ol>

<p>2.2. Każdy Klient może skorzystać z Promocji tylko jeden
raz.</p>

<p>2.3. Pakiet „Dla Biznesu” nie jest objęty promocją — jego cena ustalana jest indywidualnie, w oparciu o specyficzne potrzeby i wielkość firmy.</p>

<ol start="3" type="1">
 <li><b><span>Okres
     obowiązywania Promocji</span></b><span>
     </span></li>
</ol>

<p>3.1. Promocja obowiązuje do dnia 31 grudnia 2024 r. do
godziny 23:59.</p>

<p>3.2. Formularze kontaktowe wysłane po tym terminie nie będą
objęte Promocją, nawet jeśli zostały wypełnione częściowo lub złożone poza
obowiązującymi godzinami Promocji.</p>

<ol start="4" type="1">
 <li><b><span>Zasady
     działania Promocji</span></b><span>
     </span></li>
</ol>

<p>4.1. Promocja zapewnia nowym Klientom możliwość korzystania
z usługi przez pierwszy miesiąc za 1 zł brutto.</p>

<p>4.2. Rodzaj usługi oraz odpowiedni pakiet abonamentowy
zostanie wybrany na pierwszym spotkaniu z Konsultantem na podstawie
indywidualnych potrzeb Klienta.</p>

<p>4.3. Po zakończeniu okresu promocyjnego automatycznie
naliczana jest pełna opłata abonamentowa zgodnie z wybranym pakietem.</p>

<ol start="5" type="1">
 <li><b><span>Okres
     wypowiedzenia i zasady rezygnacji</span></b><span
    > </span></li>
</ol>

<p>5.1. Klient może zrezygnować z usługi po promocyjnym
miesiącu, korzystając z miesięcznego okresu wypowiedzenia.</p>

<p>5.2. W przypadku braku wypowiedzenia umowy przez Klienta
przed końcem promocyjnego miesiąca, pełna opłata abonamentowa zostanie
automatycznie pobrana za kolejny okres rozliczeniowy.</p>

<p>5.3. Klient może wypowiedzieć umowę w dowolnym momencie,
przy zachowaniu miesięcznego okresu wypowiedzenia.</p>


<ol start="6" type="1">
 <li><b><span>Postanowienia
     końcowe</span></b><span> </span></li>
</ol>

<p>6.1. Organizator zastrzega sobie prawo do wprowadzania zmian
w niniejszym Regulaminie oraz do przedłużenia, skrócenia lub zakończenia
Promocji przed wyznaczonym terminem z ważnych powodów, w szczególności w
przypadku zmiany przepisów prawnych.</p>

<p>6.2. Ewentualne zmiany w Regulaminie nie wpływają na warunki
Promocji przyznane Klientom, którzy wypełnili formularz kontaktowy przed datą
wprowadzenia zmian.</p>

<p><span>7.<span>&nbsp;&nbsp;&nbsp;
</span></span><b><span>Rozstrzyganie
sporów</span></b></p>

<p>7.1. Wszelkie spory wynikłe z tytułu niniejszego Regulaminu,
których nie uda się rozwiązać polubownie, będą rozstrzygane przez sąd właściwy
dla siedziby Organizatora, tj. Sąd Rejonowy dla m.st. Warszawy w Warszawie lub,
w przypadku sporów o wartości przekraczającej ustawowy próg, Sąd Okręgowy w
Warszawie.</p>

<p>7.2. Klient ma prawo do skorzystania z pozasądowych sposobów
rozwiązywania sporów, o ile istnieją takie możliwości w ramach obowiązujących
przepisów.</p>

    </div>
}