import {useInjection} from "inversify-react";
import {useCallback, useEffect, useState} from "react";
import {NewsPictureApi} from "../../api";
import ApiService from "../../services/apiService";
import {Image} from 'antd';
import SkeletonImage from "antd/lib/skeleton/Image";
import styles from "./newsImage.module.less";

export default function NewsImage(props: NewsImageProps) {
    const apiService = useInjection(ApiService);
    const [image, setImage] = useState<string>();
    const [imageId, setImageId] = useState<number>();
    const {large} = props;

    const getNewsImage = useCallback(async (controller: AbortController) => {

        if (!props.id) {
            return;
        }

        const response = large
            ? await apiService.getApi(NewsPictureApi)
                .apiNewsPictureIdFileStreamGet(props.id, props.token, {responseType: 'blob', signal: controller.signal})
            : await apiService.getApi(NewsPictureApi)
                .apiNewsPictureIdThumbnailGet(props.id, props.token, {responseType: 'blob', signal: controller.signal});

        setImage(URL.createObjectURL(response.data!));
        setImageId(props.id);
    }, [apiService, props.id, large]);

    useEffect(() => {
        const controller = new AbortController();

        if (!image || imageId !== props.id) {
            getNewsImage(controller);
        }

        return () => {
            controller.abort();

            if (image) {
                URL.revokeObjectURL(image);
            }
        };
    }, [getNewsImage, image, props.id, imageId]);

    return <>{!image
        ? <SkeletonImage className={styles.skeleton}
                         style={{width: props.width || 175, height: props.height || 150, ...props.style}}/>
        : <Image src={image} width={props.width || 175} height={props.height || 150}
                 style={{objectFit: "cover", ...props.style}} preview={false}/>}
    </>;
}

interface NewsImageProps {
    id?: number | null,
    token?: string,
    large?: boolean,
    width?: number | string,
    height?: number | string,
    style?: any
}
