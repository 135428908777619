import { Affix, Button } from "antd";
import { Link } from "react-router-dom";
import { RoutingPaths } from "../../routing/routingContainer";
import styles from "./newsCta.module.less";

export default function NewsCta() {
    return <Affix offsetTop={122}>
        <div className={styles.container}>
            <div className={styles.titleContainer}>
                <div className={styles.smallTitle}>
                    Szukasz wsparcia biznesowego?
                </div>
                <div className={styles.largeTitle}>
                    Zaufaj naszym<br/>
                    ekspertom
                </div>
            </div>
            <Link to={RoutingPaths.yourCaseAdd.route} className={styles.button}>
                <Button>
                    Zgłoś nową sprawę
                </Button>
            </Link>
        </div>
    </Affix>;
}