import { Col, Row } from "antd";
import React from "react";
import styles from "./title.module.less";

interface ITitleProps {
  title: string,
  subtitle?: string
}

export default function GweTitle(props: ITitleProps) {
  return <div className={styles.titleContainer}>
    <Row style={{ alignItems: "end" }}>
      <Col>
        {props.title}
      </Col>
      <Col>
        {props.subtitle && <h3 className={styles.subtitle}>{props.subtitle}</h3>}
      </Col>
    </Row>
  </div>;
}