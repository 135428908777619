import { FilePdfOutlined, FileZipOutlined } from "@ant-design/icons";
import { Avatar, Button, Col, Divider, List, Row, Space, Typography } from "antd";
import { Content } from "antd/lib/layout/layout";
import { Link } from "react-router-dom";
import GweLayout from "../../components/gweLayout/gweLayout";

const links = [{
    file: "GWE Ogłoszenie o zwołaniu walnego.pdf",
    title: "Ogłoszenie o zwołaniu zwyczajnego walnego zgromadzenia wspólników na dzień 22 października 2024 roku "
}, {
    file: "Raport roczny 2023.zip",
    title: "Sprawozdanie z działalności Spółki za rok 2023"
}, {
    file: "Raport roczny 2022.zip",
    title: "Sprawozdanie z działalności Spółki za rok 2022"
}, {
    file: "Raport roczny 2021.zip",
    title: "Sprawozdanie z działalności Spółki za rok 2021"
}, {
    file: "Raport roczny 2020.zip",
    title: "Sprawozdanie z działalności Spółki za rok 2020"
}, {
    file: "Raport roczny 2019.zip",
    title: "Sprawozdanie z działalności Spółki za rok 2019"
}];

export default function InvestorPage() {
    return <GweLayout title="Informacje dla Inwestorów">
        <Col xs={22} md={20} lg={18} xxl={16}>
            <Space direction="vertical" style={{ width: "100%" }}>
                <List itemLayout="horizontal" dataSource={links} renderItem={item => <List.Item>
                    <List.Item.Meta
                    avatar={item.file.endsWith(".zip") ? <FileZipOutlined /> : <FilePdfOutlined />}
                    title={<a href={`/investors/${item.file}`}>{item.title}</a>}
                    />

                </List.Item>} />
                <Divider />
                <a href="https://rejestr.dmnavigator.pl/auth/login" target="_blank" rel="noreferrer">
                    <Button type="primary">Logowanie do systemu Domu Maklerskiego</Button>
                </a>
                <Divider />
                <Space direction="vertical">
                    <Typography.Text>Goodwill Experts S.A.</Typography.Text>
                    <Typography.Text type="secondary">ul. Branickiego 15</Typography.Text>
                    <Typography.Text type="secondary">02-972 Warszawa</Typography.Text>
                    <Typography.Link href="mailto:centrala@gwexperts.pl">centrala@gwexperts.pl</Typography.Link>
                    <Typography.Text type="secondary">NIP: 5213771824</Typography.Text>
                    <Typography.Text type="secondary">KRS: 0000667548</Typography.Text>
                    <Typography.Text type="secondary">REGON: 366462381</Typography.Text>
                </Space>
            </Space>
        </Col>
    </GweLayout>
}