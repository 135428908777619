import {NewsApi, NewsDetailsDto, NotificationsApi} from "../../../api";
import {useInjection} from "inversify-react";
import ApiService from "../../../services/apiService";
import {useParams} from "react-router-dom";
import React, {useCallback, useEffect, useState} from "react";
import styles from "./userNewsListContainer.module.less";
import NewsImage from "../../../components/newsImage/newsImage";
import GweLayout from "../../../components/gweLayout/gweLayout";
import {Col, List, Row} from "antd";
import NewsCta from "../components/newsCta";
import {loadingSpin} from "../../routing/routingContainer";
import DOMPurify from 'dompurify';
import {useDispatch} from "react-redux";
import {BreadcrumbProvider} from "digimuth-components/dist";
import {PaperClipOutlined} from "@ant-design/icons";
import { setNotificationsData } from "../../../redux/notificationSlice";


export default function UserNewsDetails() {
    const apiService = useInjection(ApiService);
    const {id} = useParams();
    const [news, setNews] = useState<NewsDetailsDto | null>(null);
    const dispatch = useDispatch();

    const fetchNewsDetails = useCallback(async () => {
        if (!id) {
            return;
        }
        try {
            const news = await apiService.getApi(NewsApi).apiNewsIdGet(+id!);
            setNews(news.data);

            const newsNotifications = await apiService.getApi(NotificationsApi).apiNotificationsGet();
            dispatch(setNotificationsData(newsNotifications.data));
        } catch (e: any) {
            return e?.response.data;
        }
    }, [apiService, id, dispatch]);

    useEffect(() => {
        fetchNewsDetails();
    }, [fetchNewsDetails]);

    return <> {news !== null ?
        <BreadcrumbProvider
            contextValue={news.title!.length <= 30 ? news.title! : `${news.title?.substring(0, 30).trim()} ...`}>
            <GweLayout hideTitle={true} title={news.title!.length <= 30 ? news.title! : `${news.title?.substring(0, 30).trim()} ...`}>
                <NewsInternal news={news} />
            </GweLayout>
        </BreadcrumbProvider> : loadingSpin}
    </>;
}

export function SaasLandingNews() {
    const apiService = useInjection(ApiService);
    const [news, setNews] = useState<NewsDetailsDto | null>(null);

    const fetchNewsDetails = useCallback(async () => {
        try {
            const news = await apiService.getApi(NewsApi).apiNewsSaasLandingGet();
            setNews(news.data);
        } catch (e: any) {
            return e?.response.data;
        }
    }, [apiService]);

    useEffect(() => {
        fetchNewsDetails();
    }, [fetchNewsDetails]);

    return <> {news !== null ?
            <GweLayout hideTitle={true} title={news.title!.length <= 30 ? news.title! : `${news.title?.substring(0, 30).trim()} ...`}>
                <NewsInternal news={news} className={styles.saasNews} hideCta={true} />
            </GweLayout> : loadingSpin}
    </>;
}

function NewsInternal(props: { news: NewsDetailsDto, className?: string, hideCta?: boolean }) {
    const { news } = props;

    return <Row justify="center" style={{ flex: 1, marginTop: "-20px" }}>
        <Col xs={22} md={20} lg={18} xxl={16}>
            <Row justify="center" gutter={60}>
                <Col xs={24} lg={props.hideCta ? 24 : 16} style={{ paddingLeft: "5px" }}>
                    <div className={styles.newsDetailsContainer}>
                        <div className={styles.newsInfo}>
                            <div className={styles.newsInfoItem}>
                                <span>
                                    {new Date(news.emailSentAt || news.createdAt!)
                                        .toLocaleDateString("pl-PL")}
                                </span>
                                <span style={{ border: "1px solid #CCCCCC" }} />
                                <span>
                                    Goodwill Experts
                                </span>
                            </div>
                            <span className={styles.newsDetailsTitle}>{news.title}</span>
                        </div>
                        <div className={styles.newsDetails}>
                            <span className={styles.newsDetailsIntroduction}>{news.introduction}</span>
                            <div className={styles.newsImage}>
                                <NewsImage id={news.newsPictureId} token={news.newsPictureToken!}
                                    large={true}
                                    width="100%" height="100%" />
                            </div>
                            <div className={`${styles.newsMessageContent} ${props.className}`}
                                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(news.messageContent!) }}>
                            </div>
                            {news.newsAttachments?.length ? <div className={styles.newsAttachments}>
                                <span className={styles.title}>Załączniki</span>
                                <div className={styles.attachmentsContainer}>
                                    <List>
                                        {news.newsAttachmentsTokens?.map((n, index) => <List.Item>
                                            <div>
                                                <PaperClipOutlined />
                                                <a key={index}
                                                    className={styles.attachmentLink}
                                                    href={`/api/NewsAttachment/${n.newsAttachmentId}/FileStream?Token=${n.token}`}>
                                                    {n.attachmentFileName}
                                                </a>
                                            </div>
                                        </List.Item>
                                        )}
                                    </List>
                                </div>
                            </div> : null}

                        </div>
                    </div>
                </Col>
                <Col xs={props.hideCta ? 0 : 24} lg={props.hideCta ? 0 : 8}>
                    <NewsCta />
                </Col>
            </Row>
        </Col>
    </Row>;
}
