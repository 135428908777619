import {ArrowRightOutlined, FilePdfOutlined, LinkOutlined} from "@ant-design/icons";
import {Checkbox, Col, Skeleton, Space, Tag, Timeline, Typography} from "antd";
import {useInjection} from "inversify-react";
import moment from "moment";
import {ReactNode, useCallback, useEffect, useState} from "react";
import {
    ApplicationUserMinimalDto,
    CaseDetailsDto,
    CaseHistoryValues,
    CaseMessageDto, CaseMessageRecipientDto, CaseMessageRecipients,
    CaseUpdateHistoryApi,
    CaseUpdateHistoryDto
} from "../../../../api";
import {MessageSortDirection} from "../../../../components/caseMessage/MessageSortDirection";
import CasePeriodStatusSelector from "../../../../components/casePeriodStatusSelector/casePeriodStatusSelector";
import CaseStatusSelector from "../../../../components/caseStatusSelector/caseStatusSelector";
import { getNameForCaseType } from "../../../../components/caseTypeSelector/caseTypeSelector";
import LawsuitStatusSelector from "../../../../components/lawsuitStatusSelector/lawsuitStatusSelector";
import ProfilePicture from "../../../../components/profilePicture/profilePicture";
import ApiService from "../../../../services/apiService";
import styles from "./caseActivity.module.less";
import classNames from "classnames";
import { loadingSpin, RoutingPaths } from "../../../routing/routingContainer";
import { Link } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { C } from "@fullcalendar/core/internal-common";

const {Text, Paragraph} = Typography;

export function getTimelineActionDescription(historyItem: { oldValue?: string | null, newValue?: string | null }) {
    if (historyItem.oldValue && historyItem.newValue) {
        return "zmienił(a)";
    }

    if (historyItem.newValue) {
        return "dodał(a)";
    }

    return "usunął/usunęła";
}

export function getTimelineFieldName(changedValueName?: CaseHistoryValues, fieldName?: string) {
    switch (changedValueName) {
        case CaseHistoryValues.Status:
            return "status sprawy";
        case CaseHistoryValues.Type:
            return "typ sprawy";
        case CaseHistoryValues.Product:
            return "rodzaj sprawy";
        case CaseHistoryValues.Expert:
            return "eksperta";
        case CaseHistoryValues.Manager:
            return "managera";
        case CaseHistoryValues.CompanyName:
            return "firmę";
        case CaseHistoryValues.Description:
            return "opis";
        case CaseHistoryValues.CasePeriodStatus:
            return "status okresu rozliczeniowego";
        case CaseHistoryValues.AttachmentDescription:
            return `opis załącznika`;
        case CaseHistoryValues.Agreement:
            return "umowę z GW Experts";
        case CaseHistoryValues.ClientAttachment:
        case CaseHistoryValues.PeriodClientAttachment:
            return "załącznik klienta";
        case CaseHistoryValues.ExpertAttachment:
        case CaseHistoryValues.PeriodExpertAttachment:
            return "załącznik eksperta";
        case CaseHistoryValues.BeginDate:
            return "datę rozpoczęcia";
        case CaseHistoryValues.Name:
            return "nazwę sprawy";
        case CaseHistoryValues.DeadLine:
            return "termin zakończenia";
        case CaseHistoryValues.Plaintiff:
            return "stronę powodową";
        case CaseHistoryValues.Defendant:
            return "stronę pozwaną";
        case CaseHistoryValues.Value:
            return "wartość przedmiotu sporu";
        case CaseHistoryValues.FirstInstance:
            return "status (pierwsza instancja)";
        case CaseHistoryValues.FirstInstanceJudgeName:
            return "wyznaczonego sędziego (pierwsza instancja)";
        case CaseHistoryValues.FirstInstanceCourt:
            return "sąd (pierwsza instancja)";
        case CaseHistoryValues.FirstInstanceSignature:
            return "sygnaturę (pierwsza instancja)";
        case CaseHistoryValues.SecondInstance:
            return "status (druga instancja)";
        case CaseHistoryValues.SecondInstanceJudgeName:
            return "wyznaczonego sędziego (druga instancja)";
        case CaseHistoryValues.SecondInstanceCourt:
            return "sąd (druga instancja)";
        case CaseHistoryValues.SecondInstanceSignature:
            return "sygnaturę (druga instancja)";
        case CaseHistoryValues.Cassation:
            return "status (kasacja)";
        case CaseHistoryValues.CassationJudgeName:
            return "wyznaczonego sędziego (kasacja)";
        case CaseHistoryValues.CassationCourt:
            return "sąd (kasacja)";
        case CaseHistoryValues.CassationSignature:
            return "sygnatura (kasacja)";
        case CaseHistoryValues.RegisterRemoval:
            return "usunięcie hipoteki z księgi wieczystej";
        case CaseHistoryValues.BikRemoval:
            return "usunięcie wpisu z BIK";
        case CaseHistoryValues.Vat:
            return "podsumowanie - VAT";
        case CaseHistoryValues.CorporateTax:
            return "podsumowanie - CIT";
        case CaseHistoryValues.PersonalTax:
            return "podsumowanie - PIT";
        case CaseHistoryValues.Insurance:
            return "podsumowanie - ZUS";
        case CaseHistoryValues.Message:
            return "nowy komentarz";
        case CaseHistoryValues.Leader:
            return "lidera sprawy";
        case CaseHistoryValues.AccountingType:
            return "typ księgowości";
        case CaseHistoryValues.Subscription:
            return "abonament";
        case CaseHistoryValues.SummaryField:
            return "podsumowanie";
        case CaseHistoryValues.CalendarEvent:
            return "wydarzenie";
        case CaseHistoryValues.CaseCreated:
            return "sprawę";
        case CaseHistoryValues.Other:
            return "podsumowanie - pozostałe informacje";
        case CaseHistoryValues.UserCreated:
            return "użytkownika";
        case CaseHistoryValues.AttachmentPaidStatus:
            return "status opłacenia kosztu";
    }

    return fieldName || changedValueName;
}

function TimelineItemActionDescription(props: CaseDetailsTimelineItemProps) {
    return <Text> {getTimelineActionDescription(props.historyItem)} </Text>;
}

function TimelineFieldName(props: CaseDetailsTimelineItemProps) {
    const {historyItem} = props;

    let fieldName = getTimelineFieldName(historyItem.changedValueName, historyItem.milestoneName || undefined);

    if (historyItem.updatedAttachmentFileName) {
        fieldName += ` [${historyItem.updatedAttachmentFileName}]`;
    }

    if (historyItem.casePeriodDetailsId) {
        fieldName += ` (${historyItem.casePeriodDetails?.year}-${historyItem.casePeriodDetails?.month?.toString().padStart(2, "0")})`;
    }

    return <Text strong>{fieldName}</Text>
}

function TimelineItemValue(props: { style?: any, onEllipsis?: any, value: string, isOldValue: boolean, changeType: CaseHistoryValues }) {

    if (props.changeType === CaseHistoryValues.Message) {
        return <Paragraph ellipsis={{rows: 4, expandable: true}}
                          className={styles.message}>{props.value}</Paragraph>;
    }


    if (props.changeType === CaseHistoryValues.Status) {
        return <CaseStatusSelector editable={false} currentCase={{status: props.value as any} as any}
                                   updateCase={() => Promise.resolve({})}/>
    }

    if (props.changeType === CaseHistoryValues.Type) {
        return <Paragraph style={props.style}>{getNameForCaseType(props.value as any)}</Paragraph>
    }

    if (props.changeType === CaseHistoryValues.Product) {
        return <Paragraph style={props.style}>{props.value}</Paragraph>
    }
    
    if (props.changeType === CaseHistoryValues.FirstInstance
        || props.changeType === CaseHistoryValues.SecondInstance
        || props.changeType === CaseHistoryValues.Cassation) {
        return <LawsuitStatusSelector editable={false} value={props.value as any} onChange={() => Promise.resolve({})}/>
    }

    if (props.changeType === CaseHistoryValues.BeginDate
        || props.changeType === CaseHistoryValues.DeadLine) {
        return <Paragraph ellipsis={{rows: 4, expandable: true}} delete={props.isOldValue}>{moment(props.value).format("YYYY-MM-DD")}</Paragraph>;
    }

    if (props.changeType === CaseHistoryValues.ClientAttachment
        || props.changeType === CaseHistoryValues.ExpertAttachment
        || props.changeType === CaseHistoryValues.PeriodClientAttachment
        || props.changeType === CaseHistoryValues.PeriodExpertAttachment
        || props.changeType === CaseHistoryValues.Agreement) {

        return <Paragraph className={classNames(styles.attachmentWrapper, props.isOldValue ? styles.disabled : null)}
                          ellipsis={{rows: 4, expandable: true}}>
            <FilePdfOutlined className={styles.attachmentIcon}/>
            {props.value}
        </Paragraph>
    }

    if (props.changeType === CaseHistoryValues.AttachmentDescription) {
        return <Paragraph className={props.value == "Brak" ? styles.noOldValue : undefined}
                          style={props.style}
                          ellipsis={{
                              onEllipsis: props.onEllipsis,
                              rows: 4,
                              expandable: true
                          }}>
            {props.value}
        </Paragraph>;
    }

    if (props.changeType === CaseHistoryValues.BikRemoval
        || props.changeType === CaseHistoryValues.RegisterRemoval) {
        const checkboxValue = props.value === "True";
        return <Checkbox checked={checkboxValue}></Checkbox>;
    }

    if (props.changeType === CaseHistoryValues.CasePeriodStatus) {
        return <CasePeriodStatusSelector currentPeriod={{status: props.value as any}} editable={false}
                                         updateCase={() => Promise.resolve({})}/>;
    }

    return <Paragraph className={props.value == "Brak" ? styles.noOldValue : undefined}
                      style={props.style}
                      delete={props.isOldValue}
                      ellipsis={{
                          onEllipsis: props.onEllipsis,
                          rows: 4,
                          expandable: true
                      }}>{props.value}</Paragraph>;

}

function TimelineItemDetails(props: CaseDetailsTimelineItemProps) {
    const {historyItem} = props;
    const [oldItemEllipsis, setOldItemEllipsis] = useState<boolean>(false);
    const [newItemEllipsis, setNewItemEllipsis] = useState<boolean>(false);

    const onOldItemEllipsis = (e: boolean) => {
        if (e)
            setOldItemEllipsis(e)
    }

    const onNewItemEllipsis = (e: boolean) => {
        if (e)
            setNewItemEllipsis(e)
    }

    let oldItem = <></>;
    let arrow = <></>;
    let newItem = <></>;

    if (historyItem.oldValue) {
        oldItem = <TimelineItemValue style={oldItemEllipsis && newItemEllipsis ? {maxWidth: "50%"} : null}
                                     onEllipsis={onOldItemEllipsis} changeType={historyItem.changedValueName!}
                                     value={historyItem.oldValue}
                                     isOldValue={true}/>;
    }

    if (historyItem.newValue) {
        newItem = <TimelineItemValue style={oldItemEllipsis && newItemEllipsis ? {maxWidth: "50%"} : null}
                                     onEllipsis={onNewItemEllipsis} changeType={historyItem.changedValueName!}
                                     value={historyItem.newValue}
                                     isOldValue={false}/>;
    }

    if (historyItem.oldValue && historyItem.newValue) {
        arrow = <ArrowRightOutlined className={styles.arrow}/>;
    }

    if (historyItem.changedValueName === CaseHistoryValues.Message) {
        return <div className={styles.itemDetails}>{newItem}</div>;
    }

    return <div className={styles.itemDetails}>{oldItem} {arrow} {newItem}</div>;
}


function CaseDetailsTimelineItem(props: CaseDetailsTimelineItemProps) {
    const {historyItem} = props;
    const createdBy = historyItem.createdBy || props.createdByUser;
    const createdAt = moment(historyItem.createdAt);

    const getMessageRecipient = (recipient: CaseMessageRecipientDto, index: number) => {
        switch (recipient.type) {
            case CaseMessageRecipients.Client:
                return <Tag key={index}>
                    Klient
                </Tag>
            case CaseMessageRecipients.Expert:
                return <Tag key={index}>
                    Ekspert
                </Tag>
            case "Patron" as any:
                return <Tag key={index}>
                    Opiekun
                </Tag>
            case CaseMessageRecipients.Manager:
                return <Tag key={index}>
                    {recipient.recipient?.firstName} {recipient.recipient?.lastName} {recipient.recipient?.managerTitle?.name || "Manager"}
                </Tag>
        }
    }

    if (!createdBy) {
        return null;
    }

    return <div className={classNames(styles.itemInternal, props.isFirstInGroup ? null : styles.profilePictureOffset)}>
        {props.isFirstInGroup && <ProfilePicture size={32} profilePictureId={createdBy!.profileImageId}/>}
        <div className={styles.itemDataWrapper}>
            <Text strong>{`${createdBy!.firstName} ${createdBy!.lastName}`}</Text>
            <TimelineItemActionDescription historyItem={historyItem}/>
            <TimelineFieldName historyItem={historyItem}/>
            <Text className={styles.createdAtTime} type="secondary">{createdAt.format("HH:mm")}</Text>
            {props.historyItem.changedValueName === CaseHistoryValues.Message && props.historyItem.recipients !== null ? props.historyItem.recipients!.map((recipient, index) =>
                    getMessageRecipient(recipient, index)) : null}
            <TimelineItemDetails {...props} />
        </div>
    </div>
}

interface CaseDetailsTimelineItemProps {
    historyItem: historyItemProps;
    isFirstInGroup?: boolean,
    createdByUser?: ApplicationUserMinimalDto
}

interface historyItemProps extends CaseUpdateHistoryDto{
}

function CaseDetailsTimeLineItemGroup(props: CaseDetailsTimeLineItemGroup) {
    const createdAt = moment(props.group[0].createdAt);
    const sortedGroup = props.group.reverse();

    const getLabel = () => {
        const dateString = createdAt.format("YYYY-MM-DD");

        if (!props.groupByCase) {
            return dateString;
        }

        const caseId = props.group[0].caseId;

        return <Space direction="vertical">
            <Text type="secondary">{dateString}</Text>
            {caseId ? <Link to={RoutingPaths.caseDetails.route.replace(":id", caseId.toString())}>{caseId}
                <LinkOutlined/>
            </Link> : <div>Bez sprawy</div>}
        </Space>
    }

    return <Timeline.Item className={styles.itemWrapper}
                          label={getLabel()}>

        <Text type="secondary" className={styles.mobileDate}>{createdAt.format("YYYY-MM-DD")}</Text>
        {sortedGroup.map((h, idx) =>
            <CaseDetailsTimelineItem isFirstInGroup={idx == 0} key={h.id} historyItem={h} createdByUser={props.createdByUser}/>)}
    </Timeline.Item>
}

interface CaseDetailsTimeLineItemGroup {
    group: CaseUpdateHistoryDto[];
    groupByCase?: boolean,
    createdByUser?: ApplicationUserMinimalDto
}

export function ActivityTimeline(props: ActivityTimelineProps) {

    const [caseHistoryGroups, setCaseHistoryGroups] = useState<CaseUpdateHistoryDto[][]>();
    const [groupsToShow, setGroupsToShow] = useState<CaseUpdateHistoryDto[][]>();

    const messagesAsHistory = props.messages?.map(m => ({
        ...m,
        changedValueName: CaseHistoryValues.Message,
        newValue: m.content
    }) as CaseUpdateHistoryDto) || [];

    const groupCaseHistories = (updateHistory: CaseUpdateHistoryDto[]) => {
        let previousValue: CaseUpdateHistoryDto | null = null;
        let groups: CaseUpdateHistoryDto[][] = [];
        let idx = 0;
        updateHistory.forEach((e) => {
            if (previousValue != null) {
                if (moment(previousValue.createdAt!).format("YYYY-MM-DD") == moment(e.createdAt!).format("YYYY-MM-DD") &&
                    previousValue.createdBy?.id == e.createdBy?.id && (previousValue.caseId === e.caseId || !props.groupByCase)) {
                    groups[idx].push(e);
                } else {
                    idx += 1;
                    groups[idx] = [];
                    groups[idx].push(e);
                }
            } else {
                groups[idx] = [];
                groups[idx].push(e);
            }
            previousValue = e;
        })
        return groups;
    }

    const onShowMore = () => {
        setGroupsToShow(groupsToShow =>
            [...groupsToShow ?? [], ...caseHistoryGroups?.slice(groupsToShow?.length, (groupsToShow?.length || 0) + 10) ?? []]
        );
    }

    useEffect(() => {
        setGroupsToShow([]);
    }, [caseHistoryGroups]);

    useEffect(() => {
        const combinedHistory = [...(props.caseHistory || []), ...messagesAsHistory].sort((a, b) => (new Date(a.createdAt!) > new Date(b.createdAt!) ? 1 : -1) * (props.sortDirection === MessageSortDirection.Oldest ? -1 : 1));
        setCaseHistoryGroups(groupCaseHistories(combinedHistory));
    }, [props.caseHistory, props.messages]);

    return <Col xs={24}>
        <Timeline className={classNames(styles.timeline, "ant-timeline-label")} mode="left">
            {props.sortDirection !== MessageSortDirection.Oldest && props.initialItem}
            {props.groupByCase ? groupsToShow && <InfiniteScroll
                    style={{overflow: "visible"}}
                    next={onShowMore}
                    hasMore={groupsToShow.length !== caseHistoryGroups?.length}
                    loader={loadingSpin}
                    dataLength={groupsToShow.length}
                >
                {groupsToShow?.map((g, idx) => <CaseDetailsTimeLineItemGroup key={idx} group={g} groupByCase={props.groupByCase} createdByUser={props.createdByUser}/>)}
            </InfiniteScroll> : caseHistoryGroups?.map((g, idx) => <CaseDetailsTimeLineItemGroup key={idx} group={g} groupByCase={props.groupByCase} createdByUser={props.createdByUser}/>)}
            {props.sortDirection === MessageSortDirection.Oldest && props.initialItem}
        </Timeline>
    </Col>;
}

export default function CaseDetailsTimeline(props: CaseDetailsTimelineProps) {
    const apiService = useInjection(ApiService);
    const [caseHistory, setCaseHistory] = useState<CaseUpdateHistoryDto[]>();
    const {currentCase} = props;

    const getCaseHistory = useCallback(async () => {
        const response = await apiService.getApi(CaseUpdateHistoryApi).apiCaseUpdateHistoryCaseIdGet(currentCase.id!);

        setCaseHistory(response.data);
    }, [apiService, currentCase, props.messages]);

    useEffect(() => {
        getCaseHistory();
    }, [getCaseHistory]);

    if (!caseHistory) {
        return <Skeleton active avatar/>
    }

    const {createdBy} = currentCase;
    const createdAt = moment(currentCase.createdAt);

    return <ActivityTimeline
            initialItem={
                <Timeline.Item className={styles.itemWrapper} label={createdAt.format("YYYY-MM-DD")}>
                    <Text type="secondary" className={styles.mobileDate}>{createdAt.format("YYYY-MM-DD")}</Text>
                    <div className={styles.itemInternal}>
                        <ProfilePicture size={32} profilePictureId={createdBy!.profileImageId}/>
                        <div className={styles.itemDataWrapper}>
                            <Text strong> {`${createdBy!.firstName} ${createdBy!.lastName}`}</Text>
                            <Text> zgłosił(a) </Text><Text strong>sprawę</Text>
                            <Text className={styles.createdAtTime} type="secondary">{createdAt.format("HH:mm")}</Text>
                        </div>
                    </div>
                </Timeline.Item>} sortDirection={props.sortDirection} messages={props.messages} caseHistory={caseHistory} />
}

interface CaseDetailsTimelineProps {
    currentCase: CaseDetailsDto,
    sortDirection: MessageSortDirection,
    messages?: CaseMessageDto[]
}

interface ActivityTimelineProps {
    sortDirection: MessageSortDirection,
    messages?: CaseMessageDto[]
    caseHistory?: CaseUpdateHistoryDto[]
    initialItem?: ReactNode,
    groupByCase?: boolean,
    createdByUser?: ApplicationUserMinimalDto
}
