import { useEffect } from "react";
import { Input } from "antd";
import styles from "../forgotPasswordComplete.module.less";

export default function CheckConfirmPasswordComponent(props: any) {
    const passwordField = props.form.getFieldValue(props.passwordFieldName || "password") ?? "";
    const confirmPasswordField = props.value ?? "";

    const passwordsAreNotEmpty = passwordField.trim().length > 0
        && confirmPasswordField.trim().length > 0;

    const passwordsAreEqual: boolean = passwordField === confirmPasswordField;

    const isValid = passwordsAreEqual && passwordsAreNotEmpty;

    const { onPasswordCheck } = props;
    useEffect(() => {
        onPasswordCheck(isValid);
    }, [passwordsAreEqual, isValid, onPasswordCheck]);

    return <div>
        <Input.Password value={props.value} onChange={props.onChange} placeholder={props.placeholder} />
        <div className={styles.passwordRequirements}>
            <div className={isValid ? styles.passed : ""}>
                <div></div> Hasła muszą być identyczne
            </div>
        </div>
    </div>
};