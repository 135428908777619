import {Col, Row, Spin} from "antd";
import {CrudBreadcrumb} from "digimuth-components/dist";
import {ReactElement} from "react";
import {RoutingPaths} from "../../pages/routing/routingContainer";
import GweTitle from "../title/title";
import styles from "./gweLayout.module.less";
import { Helmet } from "react-helmet";

export default function GweLayout(props: GwLayoutProps) {
    return <>
    <Helmet>
        <title>{props.title} - GoodNet - Goodwill Experts</title>
    </Helmet>
    <div style={{flexDirection: "column", flex: 1, display: "flex", marginTop: 20}}>
        <Row justify="center">
            <Col xs={22} md={20} lg={18} xxl={16}>
                <CrudBreadcrumb routingDefinitions={RoutingPaths}/>
                <Row className={styles.headerContainer}>
                    {props.title && !props.hideTitle ? <Col>
                        <GweTitle title={props.title} subtitle={props.subtitle}/>
                        <br/>
                        {props.leftActions}
                    </Col> : null}
                    <Col>
                        {props.actions}
                    </Col>
                    {props.description && <Col xs={24}>
                        <h3 className={styles.description}>{props.description}</h3>
                    </Col>}
                </Row>
            </Col>
        </Row>
        <Spin spinning={props.isLoading || false}>
            <Row justify="center" className={styles.content}>
                {props.children}
            </Row>
        </Spin>
        {props.footer}
    </div>
    </>;
}

interface GwLayoutProps {
    children: ReactElement,
    title?: string,
    subtitle?: string,
    description?: string,
    actions?: ReactElement | null,
    leftActions?: ReactElement | null,
    footer?: ReactElement,
    isLoading?: boolean,
    hideTitle?: boolean
}
