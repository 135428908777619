import { useEffect } from "react";
import { Input } from "antd";
import styles from "../forgotPasswordComplete.module.less";

const checkNumber = (password: string) => {
    return passed(/^.*\d.*$/.test(password));
}

const checkCapital = (password: string) => {
    return passed(/^.*[A-Z].*$/.test(password));
}

const checkSpecial = (password: string) => {
    return passed(/^.*[^A-Za-z 0-9].*$/.test(password));
}

const checkLength = (password: string) => {
    return passed(password?.length >= 8 ?? false);
}

const checkAllConditions = (password: string): boolean => {
    return /^.*\d.*$/.test(password)
        && /^.*[A-Z].*$/.test(password)
        && /^.*[^A-Za-z 0-9].*$/.test(password)
        && (password?.length >= 8 ?? false);
}

const passed = (condition: boolean) => {
    return condition ? styles.passed : "";
}

export default function CheckPasswordComponent(props: any) {
    const isValid: boolean = checkAllConditions(props.value);

    const { onPasswordCheck } = props;

    useEffect(() => {
        onPasswordCheck(isValid);
    }, [isValid, onPasswordCheck]);

    return <div>
        <Input.Password value={props.value} onChange={props.onChange} placeholder={props.placeholder} />
        <div className={styles.passwordRequirements}>
            <div className={checkLength(props.value)}>
                <div></div> Minimum 8 znaków
            </div>
            <div className={checkNumber(props.value)}>
                <div></div> Minimum 1 cyfra
            </div>
            <div className={checkCapital(props.value)}>
                <div></div> Minimum 1 wielka litera
            </div>
            <div className={checkSpecial(props.value)}>
                <div></div> Minimum 1 znak specjalny
            </div>
        </div>
    </div>
};
